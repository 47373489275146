body {
  margin: 0;
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.Polaris-Choice {
  align-items: center !important;
}

.connect-to-paypal {
  padding: 0.625em 2em;
  max-width: 375px;
  font-size: 16px;
  background-image: -webkit-linear-gradient(#0070ba 20%, #0070ba) !important;
  background-image: linear-gradient(#0070ba 20%, #0070ba) !important;
  background-repeat: no-repeat !important;
  border-color: #0070ba;
  position: relative;
  white-space: nowrap;
  line-height: 1.6;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  width: max-content;
  display: block;
}
.connect-to-paypal svg {
  margin: 0px 9px 0px 0px;
  fill: #a8cde5;
  vertical-align: middle;
}
.connect-to-paypal .PPTM-btm {
  fill: #a8cde5;
}
.connect-to-paypal .PPTM-top {
  fill: #ffffff;
}
.connect-to-paypal b {
  fill: #fff;
}
.connect-to-paypal:hover,
.connect-to-paypal:active {
  background-image: -webkit-linear-gradient(#003087 20%, #003087) !important;
  background-image: linear-gradient(#003087 20%, #003087) !important;
  background-repeat: no-repeat !important;
}
.connect-to-paypal:hover b {
  text-decoration: underline;
}
.Polaris-InlineError__Icon {
  display: flex;
  align-items: center;
}
.horizontal-4 {
  margin: 0px 4px;
}
.Polaris-PositionedOverlay {
  font-size: 14px;
}
.Polaris-List__Item {
  font-size: 14px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-32 {
  margin-top: 32px;
}
.mb-16 {
  margin-bottom: 16px;
}
.margin-top-4 {
  margin-top: 4px;
}
.margin-top-8 {
  margin-top: 8px;
}
.right-content {
  justify-content: flex-end;
}
.relative {
  position: relative;
}
.province-remove-btn {
  position: absolute;
  right: 20px;
  cursor: pointer;
}
.province-remove-btn-refund {
  position: absolute;
  right: 0px;
  top: -24px;
  cursor: pointer;
}
.province-remove-img-btn {
  position: absolute;
  right: 0px;
  top: 15px;
  z-index: 2;
  background-color: white;
  cursor: pointer;
}
.Polaris-DatePicker__Header {
  position: unset;
}
.date-picker-btn-group {
  display: flex;
  justify-content: flex-end;
}
.ml-8 {
  margin-left: 8px;
}
